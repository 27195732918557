@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
* {
    box-sizing: border-box;
}
body {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
}
h2, h3, p {
    margin: 0;
}
h2 {
    margin-bottom: 15px;
}
p {
    margin-bottom: 5px;
}
#root {
    max-width: 1400px;
    margin: 0 auto;
}
.l-contents {
    max-width: 1900px;
    padding: 0 15px;
    margin: 0 auto;
}
.l-main {
    max-width: 1900px;
    padding: 0 15px;
    margin: 0 auto;
}
.l-main__image,
.l-main__code {
    box-sizing: border-box;
    float: left;
    padding: 10px;
}
.l-main__image {
    width: 70%
}
.l-main__code {
    width: 29%;
}

.images {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.images li {
    display: block;
    clear: both;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
    overflow: auto;
}
.images li img {
    max-width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.images li textarea {
    width: 100%;
    min-height: 50px;
}
.images li #textarea {
    position: relative;
    margin-bottom: 15px;
}
.images li button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    box-shadow: 0;
    border-width: 0.5px;
}

h1,
#tipsPopupButton,
#CustomCSSPopupButton,
#ThemeOptionsPopupButton {
    display: inline-block;
    vertical-align: middle;
}
#TipsPopupButton,
#CustomCSSPopupButton,
#ThemeOptionsPopupButton {
    margin-left: 30px;
    background: #03B7D9;
    color: #fff;
    border: 0;
    box-shadow: none;
    padding: 10px 40px;
}
#TipsPopup,
#CustomCSSPopup,
#ThemeOptionsPopup {
    position: fixed;
    width: 100%;
    max-width: 800px;
    background: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 1px solid #eeeeee;
    padding: 30px;
    z-index: 99999;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 50px;
    height: 80%;
    overflow-x: scroll;
    display: none;
}
.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
}

.filters {
    margin-bottom: 20px;
    background: #f8f8f8;
    padding: 20px;
    border-bottom: 1px solid #eee;
}
.filters .theme-filter {
    margin-bottom: 15px;
}
.filters .theme-filter label {
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
}
.filters .theme-filter select {
    min-height: 30px;
    font-size: 18px;
    min-width: 200px;
    text-transform: capitalize;
    border: 1px solid #eee;
    padding: 5px;
}
.tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.tabs li {
    display: inline-block;
    margin-right: 5px;
}
.tabs li a {
    text-transform: capitalize;
    cursor: pointer;
    display: block;
    background: #eee;
    border-radius: 5px;
    padding: 10px;
}
.tabs li a.active {
    background: #999;
    color: #fff;
}
.tabs li a:hover {
    opacity: 0.8;
}
.tabs li.archive {
    float: right;
}
.tabs li.archive a {
    background: #f4f4f4;
    color: #e4e4e4; 
}
.tips,
.custom-css-list,
.theme-options-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.tips li::after,
.tips::after {
    content: "";
    display: block;
    clear: both;
}
.tips li,
.custom-css-list li,
.theme-options-list {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.tips .l-main__image {
    display: none;
}
.tips .l-main__code {
    width: 100%;
    padding: 0;
}
.tips .l-main__code #textarea {
    position: relative;
}
.tips .l-main__code textarea {
    width: 100%;
    min-height: 100px;
}
.tips .l-main__code button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    box-shadow: 0;
    border-width: 0.5px;
}

.search-input {
    margin-bottom: 15px;
    min-height: 30px;
}
#CustomCSSPopup h2,
#ThemeOptionsPopup h2 {
    margin-right: 15px;
}
#CustomCSSPopup h2,
#CustomCSSPopup .search-input,
#ThemeOptionsPopup h2,
#ThemeOptionsPopup .search-input {
    display: inline-block;
    vertical-align: middle;
}
.custom-css-list,
.theme-options-list {
    margin-top: 30px;
}
.custom-css-list #textarea {
    position: relative;
}
.custom-css-list textarea {
    width: 100%;
    min-height: 100px;
}
.custom-css-list button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    box-shadow: 0;
    border-width: 0.5px;
}
.custom-css-list h3 {
    margin-bottom: 10px;
}
.custom-css-list h3 .theme,
.theme-options-list h3 .theme {
    display: inline-block;
    background: #999;
    color: #fff;
    margin-right: 10px;
    padding: 2px 8px;
    border-radius: 3px;
}
.custom-css-list .code-area,
.theme-options-list .code-area {
    margin-top: 20px;
}
.custom-css-list .location {
    font-size: 12px;
    background: #eee;
    padding: 2px 5px;
    display: inline-block;
}
.custom-css-list .location span {
    color: #03B7D9;
}
.custom-css-list .code-description,
.theme-options-list .code-description {
    margin-bottom: 5px;
}
.theme-options-list img {
    width: auto;
    max-width: 100%;
}
.theme-options-list .code-description {
    font-weight: 600;
}